import React from 'react';
import { Link } from 'gatsby';
import { Seo } from '../../../modules/common/components/seo';
import { DashboardLayout } from '../../../modules/layout/components/dashboard-layout';
import { StaticImage } from 'gatsby-plugin-image';
import { SectionHeader } from '../../../modules/common/components/section-header';

import '../../generic-page.scss';
import '../index.scss';
import './afk-guide.scss';
import { AFKCharacter } from '../../../modules/afk/common/components/afk-character';
import { Col, Row } from 'react-bootstrap';
import { CategoryCard } from '../../../modules/cs/main/components/category-card';
import { YoutubeEmbed } from '../../../modules/common/components/youtube-embed';

const AFKGuidesTeamsPVP: React.FC = () => {
  return (
    <DashboardLayout
      className={'generic-page afk-journey afk-guide'}
      game="afk"
    >
      <ul className="breadcrumb">
        <li>
          <Link to="/afk-journey/">AFK Journey</Link>
        </li>
        <li className="divider">/</li>
        <li>
          <Link to="/afk-journey/guides">Guides</Link>
        </li>
        <li className="divider">/</li>
        <li>Supreme Arena Teams</li>
      </ul>
      <div className="page-header">
        <div className="page-icon">
          <StaticImage
            src="../../../images/afk/categories/category_sarena.webp"
            alt="Guides"
          />
        </div>
        <div className="page-details">
          <h1>Supreme Arena Teams [Week 10]</h1>
          <h2>
            Best teams to use in AFK Journey Supreme Arena/PVP mode - both on
            defense and offense.
          </h2>
          <p>
            Last updated: <strong>03/Apr/2025</strong>
          </p>
        </div>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297235"></div>
      <div className="page-content">
        <SectionHeader title="Introduction" />
        <p>
          In the AFK Journey Supreme Arena, many of the archetypes used in the
          non-season arena can be used here as well, but many opportunities are
          made available by season talents and artifacts. For attack teams, it
          is often necessary to tailor your teams based on what the other player
          is running, so below we will list some solid defense teams.
        </p>
        <p>Check out our Arena Guide here!</p>
        <Row
          xs={1}
          md={2}
          lg={2}
          xxl={3}
          className="g-3 category-cards-container"
        >
          <CategoryCard
            title="Best Arena Teams"
            link="/afk-journey/guides/arena-teams"
            image={
              <StaticImage
                src="../../../images/afk/categories/category_meta.jpg"
                alt="Best Arena Teams"
              />
            }
          />
        </Row>
        <SectionHeader title="Map 1" />
        <h5>Sonja Hypercarry</h5>
        <StaticImage
          src="../../../images/afk/dream_s/sarena_1.webp"
          alt="Guides"
        />
        <div className="heroes with-margin">
          <div className="employees-container afk-cards">
            <AFKCharacter
              mode="card"
              slug="sonja"
              showLabel
              showTags
              showIcon
              enablePopover
            />
            <AFKCharacter
              mode="card"
              slug="hewynn"
              showLabel
              showTags
              showIcon
              enablePopover
            />
            <AFKCharacter
              mode="card"
              slug="sinbad"
              showLabel
              showTags
              showIcon
              enablePopover
            />
            <AFKCharacter
              mode="card"
              slug="rowan"
              showLabel
              showTags
              showIcon
              enablePopover
            />
            <AFKCharacter
              mode="card"
              slug="lucius"
              showLabel
              showTags
              showIcon
              enablePopover
            />
          </div>
          <div className="info">
            <p>
              This team takes advantage of the favourable tiles to push Sonja
              and Lucca to their maximum potential, with plenty of healing for
              stall purposes.
            </p>
            <ul className="bigger-margin">
              <li>
                <AFKCharacter mode="inline" slug="sonja" enablePopover /> is our
                main win condition, dealing great damage while continuously
                enhancing our team.
              </li>
              <ul className="bigger-margin">
                <li>
                  Lacking Sonja, some other good lightbearer options are{' '}
                  <AFKCharacter mode="inline" slug="cyran" enablePopover /> or{' '}
                  <AFKCharacter mode="inline" slug="vala" enablePopover />.
                </li>
              </ul>
              <li>
                <AFKCharacter mode="inline" slug="hewynn" enablePopover /> is
                used due to the massive haste inherent to this team making her
                powerful ultimate less clunky to use.
              </li>
              <ul className="bigger-margin">
                <li>
                  Another option here is
                  <AFKCharacter mode="inline" slug="koko" enablePopover />.
                </li>
              </ul>
              <li>
                <AFKCharacter mode="inline" slug="sinbad" enablePopover />{' '}
                provides some great debuffs as well as damage.
              </li>
              <ul className="bigger-margin">
                <li>We can also just run another damage carry here</li>
              </ul>
              <li>
                <AFKCharacter mode="inline" slug="rowan" enablePopover /> is
                used to further speed up the team's ultimate cycling.
              </li>
              <ul className="bigger-margin">
                <li>
                  Another option is bringing another support with more immediate
                  healing to complement Hewynn.
                </li>
              </ul>
              <li>
                <AFKCharacter mode="inline" slug="lucius" enablePopover /> is
                used here as he can take advantage of Rowan’s energy regen
              </li>
              <ul className="bigger-margin">
                <li>
                  Alternatively,{' '}
                  <AFKCharacter mode="inline" slug="lucca" enablePopover /> can
                  also work here if you got him at higher dupes than Lucius.
                </li>
                <li>
                  You can also bring any other tank as long as they can survive
                  long enough to at least stall out the enemy team.
                </li>
              </ul>
            </ul>
          </div>
        </div>
        <h5>Athalia Celestial Team</h5>
        <StaticImage
          src="../../../images/afk/dream_s/sarena_2.webp"
          alt="Guides"
        />
        <div className="heroes with-margin">
          <div className="employees-container afk-cards">
            <AFKCharacter
              mode="card"
              slug="athalia"
              showLabel
              showTags
              showIcon
              enablePopover
            />
            <AFKCharacter
              mode="card"
              slug="dunlingr"
              showLabel
              showTags
              showIcon
              enablePopover
            />
            <AFKCharacter
              mode="card"
              slug="scarlita"
              showLabel
              showTags
              showIcon
              enablePopover
            />
            <AFKCharacter
              mode="card"
              slug="ludovic"
              showLabel
              showTags
              showIcon
              enablePopover
            />
            <AFKCharacter
              mode="card"
              slug="mikola"
              showLabel
              showTags
              showIcon
              enablePopover
            />
          </div>
          <div className="info">
            <p>
              This team is based around Athalia, taking advantage of the hidden
              tiles to keep Dunlingr hidden and possibly cause the enemy to
              bring ultimate-reliant heroes.
            </p>
            <ul className="bigger-margin">
              <li>
                <AFKCharacter mode="inline" slug="athalia" enablePopover /> is
                our main win condition, dealing high burst damage while not
                being overly reliant on ultimates.
              </li>
              <ul className="bigger-margin">
                <li>
                  <AFKCharacter mode="inline" slug="dionel" enablePopover />{' '}
                  also works here with Dunlingr.
                </li>
              </ul>
              <li>
                <AFKCharacter mode="inline" slug="dunlingr" enablePopover />{' '}
                helps nerf the enemy team while not impacting our team too much.
              </li>
              <ul className="bigger-margin">
                <li>
                  This slot is not easily replaceable due to his unique effect,
                  but you can run another tank in a pinch.
                </li>
              </ul>
              <li>
                <AFKCharacter mode="inline" slug="scarlita" enablePopover />{' '}
                provides shields while also acting as an additional win
                condition for the team.
              </li>
              <ul className="bigger-margin">
                <li>
                  She can be easily replaced by any other celestial/hypogean if
                  you have them at higher investment.
                </li>
              </ul>
              <li>
                <AFKCharacter mode="inline" slug="ludovic" enablePopover />{' '}
                provides some initial healing to help protect dunlingr from
                dying early while also not relying much on his ult.
              </li>
              <ul className="bigger-margin">
                <li>
                  <AFKCharacter mode="inline" slug="damian" enablePopover />{' '}
                  also works here if you are not using him on another team.
                </li>
              </ul>
              <li>
                <AFKCharacter mode="inline" slug="mikola" enablePopover />{' '}
                provides buffs and also some healing, also not overly ultimate
                reliant.
              </li>
              <ul className="bigger-margin">
                <li>
                  In the wilders version,{' '}
                  <AFKCharacter mode="inline" slug="koko" enablePopover /> can
                  also work here if her ultimate lines up with Dunlingr’s
                  downtime.
                </li>
              </ul>
            </ul>
          </div>
        </div>
        <SectionHeader title="Map 2" />
        <h5>Tasi Talene Team (Wilder Variant)</h5>
        <StaticImage
          src="../../../images/afk/dream_s/sarena_3.webp"
          alt="Guides"
        />
        <div className="heroes with-margin">
          <div className="employees-container afk-cards">
            <AFKCharacter
              mode="card"
              slug="tasi"
              showLabel
              showTags
              showIcon
              enablePopover
            />
            <AFKCharacter
              mode="card"
              slug="talene"
              showLabel
              showTags
              showIcon
              enablePopover
            />
            <AFKCharacter
              mode="card"
              slug="damian"
              showLabel
              showTags
              showIcon
              enablePopover
            />
            <AFKCharacter
              mode="card"
              slug="granny-dahnie"
              showLabel
              showTags
              showIcon
              enablePopover
            />
            <AFKCharacter
              mode="card"
              slug="cyran"
              showLabel
              showTags
              showIcon
              enablePopover
            />
          </div>
          <div className="info">
            <p>
              This team relies on Tasi and Talene, along with Damian to make for
              a hard-to-kill comp that allows Tasi to solo the enemy team.
            </p>
            <ul className="bigger-margin">
              <li>
                <AFKCharacter mode="inline" slug="tasi" enablePopover /> is one
                of the main victory conditions for this team, often capable of
                coming back after death to slay the enemy team all by herself.
              </li>
              <ul className="bigger-margin">
                <li>
                  While <AFKCharacter mode="inline" slug="tasi" enablePopover />{' '}
                  is a key component of this team, lacking Tasi, it is possible
                  to run a team tailored around{' '}
                  <AFKCharacter mode="inline" slug="talene" enablePopover />{' '}
                  instead, in which case{' '}
                  <AFKCharacter mode="inline" slug="ludovic" enablePopover /> or{' '}
                  <AFKCharacter mode="inline" slug="hewynn" enablePopover /> can
                  be a great addition here, though the team will be lacking in
                  offensive potential without Tasi.
                </li>
              </ul>
              <li>
                <AFKCharacter mode="inline" slug="talene" enablePopover /> is
                our second main victory condition, capable of regenerating after
                being defeated as long as another ally is still alive on the
                field, providing incredible synergy with{' '}
                <AFKCharacter mode="inline" slug="tasi" enablePopover />
                and <AFKCharacter mode="inline" slug="damian" enablePopover />.
                The talent tile also greatly enhances Talene’s ultimate and
                grants her extra stats.
              </li>
              <ul className="bigger-margin">
                <li>
                  It is possible to run a version of this team without{' '}
                  <AFKCharacter mode="inline" slug="talene" enablePopover />,
                  focusing instead on disruption.{' '}
                  <AFKCharacter mode="inline" slug="temesia" enablePopover />{' '}
                  can be a great choice for this variant.
                </li>
                <li>
                  <AFKCharacter mode="inline" slug="igor" enablePopover /> can
                  be used as an alternative distraction to keep{' '}
                  <AFKCharacter mode="inline" slug="tasi" enablePopover />
                  and <AFKCharacter
                    mode="inline"
                    slug="damian"
                    enablePopover
                  />{' '}
                  in the game.
                </li>
                <li>
                  We can also just run extra healing, or another damage carry,
                  though the team loses a lot of what makes it special without
                  <AFKCharacter mode="inline" slug="talene" enablePopover />.
                </li>
              </ul>
              <li>
                <AFKCharacter mode="inline" slug="damian" enablePopover />
                is the only healer that can keep up with Talene and Tasi against
                burst teams, as he can just summon a new cart to quickly heal
                Talene and Tasi back up after the rest of the team is wiped by a
                powerful attack. Works at Mythic+.
              </li>
              <ul className="bigger-margin">
                <li>
                  Lacking Damian, we can run any other healer here, though the
                  team will be a lot weaker against burst damage, as Damian is
                  the only one who can survive to provide backup healing for
                  Talene and Tasi.
                </li>
                <li>
                  For the lightbearer core,{' '}
                  <AFKCharacter mode="inline" slug="hugin" enablePopover /> can
                  work great here, but for Talene, healing is generally better.
                </li>
              </ul>
              <li>
                <AFKCharacter
                  mode="inline"
                  slug="granny-dahnie"
                  enablePopover
                />{' '}
                is used due to the asymmetrical map, and for the faction bonus.
              </li>
              <ul className="bigger-margin">
                <li>
                  Alternatively, we can use a different faction tank and build
                  the team around a different faction tile.
                </li>
              </ul>
              <li>
                <AFKCharacter mode="inline" slug="cyran" enablePopover /> is
                very powerful as he can deny the enemy team their artifact,
                while also bringing powerful CC.
              </li>
              <ul className="bigger-margin">
                <li>
                  We can also run an additional healer for more staying power.
                </li>
              </ul>
            </ul>
            <p>
              {' '}
              Overall, Tasi and Talene are the most important pieces, so when
              looking for substitutions, prioritize replacing the other
              characters in this team.
            </p>
          </div>
        </div>
        <h5>Tasi Talene Team (Lightbearer Variant)</h5>
        <StaticImage
          src="../../../images/afk/dream_s/sarena_4.webp"
          alt="Guides"
        />
        <p>
          This is another possible variant, using lightbearer faction tiles
          instead.
        </p>
        <SectionHeader title="Map 3" />
        <h5>Wilder Burst Team (Twins Variant)</h5>
        <StaticImage
          src="../../../images/afk/dream_s/sarena_5.webp"
          alt="Guides"
        />
        <div className="heroes with-margin">
          <div className="employees-container afk-cards">
            <AFKCharacter
              mode="card"
              slug="eironn"
              showLabel
              showTags
              showIcon
              enablePopover
            />
            <AFKCharacter
              mode="card"
              slug="elijah-and-lailah"
              showLabel
              showTags
              showIcon
              enablePopover
            />
            <AFKCharacter
              mode="card"
              slug="arden"
              showLabel
              showTags
              showIcon
              enablePopover
            />
            <AFKCharacter
              mode="card"
              slug="lily-may"
              showLabel
              showTags
              showIcon
              enablePopover
            />
            <AFKCharacter
              mode="card"
              slug="bryon"
              showLabel
              showTags
              showIcon
              enablePopover
            />
          </div>
          <div className="info">
            <p>
              This team is relatively accessible thanks to not necessarily
              requiring Celestial/Hypogean heroes, while also being very
              powerful against many matchups. We can make use of the hidden
              tiles to counter Lily May using Niru, or make it so that the enemy
              does not bring Lily May at a glance.
            </p>
            <ul className="bigger-margin">
              <li>
                <AFKCharacter mode="inline" slug="eironn" enablePopover /> is
                used to group up and debuff enemies and is the core of this
                archetype.
              </li>
              <ul className="bigger-margin">
                <li>
                  Of course, we can always run other sources of CC for our
                  frontline, such as{' '}
                  <AFKCharacter
                    mode="inline"
                    slug="granny-dahnie"
                    enablePopover
                  />
                  , <AFKCharacter mode="inline" slug="lumont" enablePopover />,
                  or <AFKCharacter mode="inline" slug="lucca" enablePopover />,
                  but they will lack the explosive potential of the main Eironn
                  variant.
                </li>
              </ul>
              <li>
                <AFKCharacter
                  mode="inline"
                  slug="elijah-and-lailah"
                  enablePopover
                />{' '}
                help provide energy and healing to the team, while also offering
                haste to help cycle through abilities.
              </li>
              <ul className="bigger-margin">
                <li>
                  Lacking{' '}
                  <AFKCharacter
                    mode="inline"
                    slug="elijah-and-lailah"
                    enablePopover
                  />
                  , we can also use{' '}
                  <AFKCharacter mode="inline" slug="ulmus" enablePopover /> to
                  lean more into the CC aspect, at the cost of sustain.
                </li>
              </ul>
              <li>
                <AFKCharacter mode="inline" slug="arden" enablePopover /> is the
                preferred damage carry for this team, and his dupes are readily
                available
              </li>
              <ul className="bigger-margin">
                <li>
                  <AFKCharacter mode="inline" slug="carolina" enablePopover />{' '}
                  can be used instead of Arden, or along with Arden as long as
                  we drop one of our supports for more damage.
                </li>
                <li>
                  <AFKCharacter mode="inline" slug="parisa" enablePopover /> is
                  just as powerful as a burst damage damage carry, lacking
                  either of the others
                </li>
              </ul>
              <li>
                <AFKCharacter mode="inline" slug="lily-may" enablePopover /> is
                always a good choice in most teams, including this one, offering
                damage and disruption in her ultimate denial.
              </li>
              <ul className="bigger-margin">
                <li>
                  Another good option is running{' '}
                  <AFKCharacter mode="inline" slug="lorsan" enablePopover />.
                  Lorsan takes advantage of the burst damage archetype to double
                  up on Arden’s damage while also providing a powerful burst of
                  healing. The Wilder talent tile makes it so that Lorsan can
                  usually survive long enough to trigger his healing burst,
                  though depending on the matchup he can still die as he is
                  quite squishy.
                </li>
                <li>
                  <AFKCharacter mode="inline" slug="damian" enablePopover /> can
                  be used for more sustain, as he provides some initial healing
                  that helps keep the backline alive at the start of battle,
                  while also providing Haste and blinding enemies.
                </li>
                <li>
                  <AFKCharacter mode="inline" slug="scarlita" enablePopover />{' '}
                  can provide some shields allowing the team to survive the
                  initial few seconds, while also providing some explosive
                  potential to close out games
                </li>
                <li>
                  <AFKCharacter mode="inline" slug="carolina" enablePopover />{' '}
                  complements{' '}
                  <AFKCharacter mode="inline" slug="arden" enablePopover />,
                  adding more burst damage to the team
                </li>
                <li>
                  Alternatively, we can opt for an alternate damage carry, such
                  as <AFKCharacter mode="inline" slug="bonnie" enablePopover />,
                  providing long lasting AOE damage
                </li>
              </ul>
              <li>
                We run <AFKCharacter mode="inline" slug="bryon" enablePopover />{' '}
                here as a counter to Lily May, allowing Eironn’s ultimate to go
                through, and also providing some true damage conversion through
                his buff tile.
              </li>
              <ul className="bigger-margin">
                <li>
                  If the enemy does not have
                  <AFKCharacter mode="inline" slug="lily-may" enablePopover />,
                  another good option to bring is{' '}
                  <AFKCharacter mode="inline" slug="lorsan" enablePopover />.
                </li>
                <li>
                  We can also run{' '}
                  <AFKCharacter mode="inline" slug="hewynn" enablePopover /> to
                  provide some staying power, as well as supporting our
                  frontline, though running the risk of the enemy countering our
                  Eironn.
                </li>
                <li>
                  <AFKCharacter mode="inline" slug="ludovic" enablePopover />{' '}
                  can also help provide some more immediate healing.
                </li>
                <li>
                  We can also run a more offensive alternative relying on more
                  damage carries.
                </li>
                <li>
                  Celestials/Hypogeans such as{' '}
                  <AFKCharacter mode="inline" slug="scarlita" enablePopover />,{' '}
                  <AFKCharacter mode="inline" slug="berial" enablePopover /> or{' '}
                  <AFKCharacter mode="inline" slug="harak" enablePopover /> can
                  be useful as an additional threat with more staying power.{' '}
                  <AFKCharacter mode="inline" slug="reinier" enablePopover />{' '}
                  can be used to disrupt the enemy formation.
                </li>
              </ul>
            </ul>
          </div>
        </div>
        <h5>Wilder Burst Team (Ulmus Variant)</h5>
        <StaticImage
          src="../../../images/afk/dream_s/sarena_6.webp"
          alt="Guides"
        />
        <p>
          This version replaces The Twins with Ulmus, adding more CC to the
          team.
        </p>
        <SectionHeader title="Video" />
        <p>Here are some additional ideas for teams to use:</p>
        <Row className="video">
          <Col xs={12} lg={6}>
            <YoutubeEmbed embedId="nF24ala9aN4" />
          </Col>
        </Row>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297238"></div>
    </DashboardLayout>
  );
};

export default AFKGuidesTeamsPVP;

export const Head: React.FC = () => (
  <Seo
    title="Supreme Arena Teams | AFK Journey | Prydwen Institute"
    description="Best teams to use in AFK Journey Supreme Arena/PVP mode - both on defense and offense."
    game="afk"
  />
);
